import React, { useEffect, useState, useRef } from "react";
import CommonColorForm from "../../../components/common-color-form/commonColorForm";
import ScreenLabel from "../../../components/label/screen-label/screenLabel";
import SubTextLabel from "../../../components/label/sub-text-label/subTextLabel";
import CommonScreenContent from "../../../components/screen/common-screen-content/commonScreenContent";
import './styles.css'
import Input from '@mui/material/Input';
import ButtonRow from "../../../components/button/button-row/buttonRow";
import CommonButton from "../../../components/button/common-button/commonButton";
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import QRCode from 'qrcode.react';
import { useNavigate, useLocation } from 'react-router-dom';
import { convertToPascal, displayConsoleLog, displayErrorToast, displaySuccessToast, isEmpty, isNull } from "../../../utils/Utils";
import html2canvas from 'html2canvas'
import Loader from '../../../components/loader/loader.component'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux'
import { addQrCodeRequest, addQrCodeRequestStatus, updateQrCodeRequest, updateQrCodeRequestStatus } from '../../../redux/custom-qr-code/customQrCode.action'
import { registerByMobileRequest, displaySideBarAct } from '../../../redux/user/user.action'
import { digitalCardListRequest } from '../../../redux/digital-card/digitalCard.action'
import { API_CONSTANTS, APP_CONSTANTS, ROUTES } from "../../../utils/AppConstants";
import CommonInputRow from "../../../components/input/common-input-row/commonInputRow";
import TopButton from "../../../components/button/top-button/topButton";
import FormFieldLabel from "../../../components/label/form-field-label/formFieldLabel";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { toSvg, toPng, toJpeg } from "html-to-image";
import download from "downloadjs";
import CommonInputFloatFull from "../../../components/input/common-input-float-full/commonInputFloatFull";
import CommonInputFloatHalf from "../../../components/input/common-input-float-half/commonInputFloafHalf";
import Switch from '@mui/material/Switch';
import { config } from "../../../config";
import 'react-phone-number-input/style.css'
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
import Modal from 'react-bootstrap/Modal';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import ClickAwayListener from '@mui/material/ClickAwayListener';

const CreateCustomQrCode = props => {
    const anchorRef = useRef(null);
    const [open, setOpen] = useState(false);
    const { state } = useLocation()
    const ariaLabel = { 'aria-label': 'description' };
    const label = { inputProps: { 'aria-label': 'Save for later' } };
    const { user, commonError, loginErrorData, isLogin } = useSelector((state) => state.user)
    const { addQrCodeSuccess, addQrCodeError, addQrCodeReqStatus,
        updateQrCodeSuccess, updateQrCodeError, updateQrCodeReqStatus } = useSelector((state) => state.qrCode)
    const { cardListSuccess, cardListError } = useSelector((state) => state.digitalCard)
    const [qrCodeUrl, setQRCodeUrl] = useState('')
    const [qrCodeStaticUrl, setQRCodeStaticUrl] = useState('')
    const [qrCodeInput, setQrCodeInput] = useState('')
    const [displayQrCode, setDisplayQrCode] = useState(false)
    const [isLoading, setLoading] = useState(false)
    const [customeMessage, setCustomMessage] = useState('')
    const [isSaveForLater, setIsSaveForLater] = useState(false)
    const dispatch = useDispatch()
    let navigate = useNavigate();
    const [selectedType, setSelectedType] = useState()
    const data = [
        { id: 1, type: 'LINK', name: 'LINK', selected: false, image: require('../../../assets/ic_qr_url.png'), selImage: require('../../../assets/ic_qr_url_sel.png'), isDifferentColor: false },
        { id: 2, type: 'BUSINESS CARD', name: 'BUSINESS CARD', selected: false, image: require('../../../assets/ic_qr_card_new.png'), selImage: require('../../../assets/ic_qr_card_new.png'), isDifferentColor: true },
        { id: 3, type: 'TEXT', name: 'TEXT', selected: false, image: require('../../../assets/ic_qr_text.png'), selImage: require('../../../assets/ic_qr_text_sel.png'), isDifferentColor: false },
        { id: 4, type: 'CALL', name: 'CALL', selected: false, image: require('../../../assets/ic_qr_call.png'), selImage: require('../../../assets/ic_qr_call_sel.png'), isDifferentColor: false },
        { id: 5, type: 'WHATSAPP', name: 'WHATSAPP', selected: false, image: require('../../../assets/ic_qr_whatsapp.png'), selImage: require('../../../assets/ic_qr_whatsapp_sel.png'), isDifferentColor: false },
        { id: 6, type: 'EMAIL', name: 'EMAIL', selected: false, image: require('../../../assets/ic_qr_email.png'), selImage: require('../../../assets/ic_qr_email_sel.png'), isDifferentColor: false },
        { id: 7, type: 'SMS', name: 'SMS', selected: false, image: require('../../../assets/ic_qr_sms.png'), selImage: require('../../../assets/ic_qr_sms_sel.png'), isDifferentColor: false },
        { id: 8, type: 'WIFI', name: 'WIFI', selected: false, image: require('../../../assets/ic_qr_wifi.png'), selImage: require('../../../assets/ic_qr_wifi_sel.png'), isDifferentColor: false },
        { id: 9, type: 'ADDRESS', name: 'ADDRESS', selected: false, image: require('../../../assets/ic_qr_address.png'), selImage: require('../../../assets/ic_qr_address_sel.png'), isDifferentColor: false },
        { id: 10, type: 'SOCIAL', name: 'SOCIAL', selected: false, image: require('../../../assets/ic_qr_social.png'), selImage: require('../../../assets/ic_qr_social_sel.png'), isDifferentColor: false },
        { id: 11, type: 'APP STORE', name: 'APP STORE', selected: false, image: require('../../../assets/ic_qr_app_center.png'), selImage: require('../../../assets/ic_qr_app_center_sel.png'), isDifferentColor: false },
        { id: 12, type: 'ONLINE MENU', name: 'ONLINE MENU', selected: false, image: require('../../../assets/ic_qr_menu_new.png'), selImage: require('../../../assets/ic_qr_menu_new.png'), isDifferentColor: true },
        { id: 12, type: 'VISITOR MANAGEMENT', name: 'VISITOR MGMT', selected: false, image: require('../../../assets/ic_qr_visitor_management.png'), selImage: require('../../../assets/ic_qr_visitor_management.png'), isDifferentColor: true },
        { id: 12, type: 'QME', name: 'QUEUE MGMT', selected: false, image: require('../../../assets/ic_qr_qme.png'), selImage: require('../../../assets/ic_qr_qme.png'), isDifferentColor: true },
        { id: 12, type: 'JUSTGOMO', name: 'JUSTGOMO', selected: false, image: require('../../../assets/ic_qr_just_gomo.png'), selImage: require('../../../assets/ic_qr_just_gomo.png'), isDifferentColor: true }
    ]
    const [forColor, setForColor] = useState('#000000')
    const [backColor, setBackColor] = useState('#ffffff')
    const [qrLogoImage, setQrLogoImage] = useState(null);
    const [qrLogoImageName, setQrLogoImageName] = useState('');
    const [qrLogoImagePreview, setQrLogoImagePreview] = useState(null);
    const [qrStyle, setQrStyle] = useState('squares')
    const [qrLogoStyle, setQrLogoStyle] = useState('square')
    const [downloadType, setDownloadType] = useState('png')
    const svgRef = useRef(null);
    const [linkText, setLinkText] = useState('')
    const [text, setText] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [toEmail, setToEmail] = useState('')
    const [emailSubject, setEmailSubject] = useState('')
    const [emailBody, setEmailBody] = useState('')
    const [ssid, setSsid] = useState('')
    const [password, setPassword] = useState('')
    const [security, setSecurity] = useState('')
    const [isDynamicQr, setIsDynamicQr] = useState(false)
    const [dyanmicQrData, setDynamicQrData] = useState(null)
    const [qrSize, setQrSize] = useState(256)
    const [qrImgSize, setQrImgSize] = useState({ width: 35, height: 17.5 })
    const [labelSize, setLabelSize] = useState(14)
    const [customTextSize, setCustomTextSize] = useState(28)
    const [mobile, setMobile] = useState()
    const [showLoginDialog, setShowLoginDialog] = useState(false)
    const [flowType, setFlowType] = useState('')
    const [navigateFlow, setNavigateFlow] = useState('')
    const [navigateToCardList, setNavigateToCardList] = useState(false)

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        } else if (event.key === 'Escape') {
            setOpen(false);
        }
    }

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = React.useRef(open);
    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);


    useEffect(() => {
        if (!isNull(commonError)) {
            if (commonError.status === 401) {
                setLoading(false)
                displayErrorToast(commonError.message)
                dispatch({ type: APP_CONSTANTS.LOGOUT })
            }
        }
    }, [commonError])

    useEffect(() => {
        setFlowType('')
        setNavigateFlow('')
        if (!isEmpty(data))
            handleCardClick(data[0])
    }, [])

    useEffect(() => {
        if (addQrCodeReqStatus === API_CONSTANTS.SUCCESS_STATUS) {
            setLoading(false)
            displaySuccessToast(addQrCodeSuccess.message)
            if (addQrCodeSuccess.data?.isDynamicQr === true) {
                setQRCodeUrl(`${config.dynamicQrCodeUrl}/${addQrCodeSuccess.data.qrCodeId}`)
                setDynamicQrData(addQrCodeSuccess.data)
            }
        } else if (addQrCodeReqStatus === API_CONSTANTS.ERROR_STATUS) {
            setLoading(false)
            displayErrorToast(addQrCodeError.message)
        }
        if (addQrCodeReqStatus.length) {
            dispatch(addQrCodeRequestStatus(''))
        }
    }, [addQrCodeSuccess, addQrCodeError, addQrCodeReqStatus])

    useEffect(() => {
        if (updateQrCodeReqStatus === API_CONSTANTS.SUCCESS_STATUS) {
            setLoading(false)
            displaySuccessToast(updateQrCodeSuccess.message)
        } else if (updateQrCodeReqStatus === API_CONSTANTS.ERROR_STATUS) {
            setLoading(false)
            displayErrorToast(updateQrCodeError.message)
        }
        if (updateQrCodeReqStatus?.length) {
            dispatch(updateQrCodeRequestStatus(''))
        }
    }, [updateQrCodeSuccess, updateQrCodeError, updateQrCodeReqStatus])

    useEffect(() => {
        displayConsoleLog('navigateFlow', navigateFlow)
        if (!isEmpty(navigateFlow) && navigateFlow === 1) {
            if (isLogin) {
                setNavigateFlow('')
                setLoading(false)
                sessionStorage.setItem(APP_CONSTANTS.USER, JSON.stringify(user.data))
                sessionStorage.setItem(APP_CONSTANTS.USER_TOKEN, user.data.authToken)
                localStorage.setItem(APP_CONSTANTS.USER, user.data.authToken)
                dispatch(displaySideBarAct(true))
                if (flowType === 1) {
                    setFlowType('')
                    handleSaveChangesClick()
                } else if (flowType === 2) {
                    setFlowType('')
                    handleSaveLaterClick()
                } else if (flowType === 3) {
                    setFlowType('')
                    if (!isNull(cardListSuccess) && !isEmpty(cardListSuccess.data)) {
                        if (cardListSuccess.data.length < 2) {
                            navigate(ROUTES.addCard)
                        } else {
                            navigate(ROUTES.cardList)
                        }
                    } else {
                        setNavigateToCardList(true)
                        fetchCardList()
                    }
                }
            } else {
                if (loginErrorData) {
                    setLoading(false)
                    displayErrorToast(loginErrorData.message)
                }
            }
        }
    }, [isLogin, loginErrorData])

    useEffect(() => {
        if (navigateToCardList) {
            if (!isNull(cardListSuccess)) {
                setLoading(false)
                if (!isEmpty(cardListSuccess.data) && cardListSuccess.data.length < 2) {
                    navigate(ROUTES.addCard)
                    setNavigateToCardList(false)
                } else {
                    navigate(ROUTES.cardList)
                    setNavigateToCardList(false)
                }
            } else if (!isNull(cardListError)) {
                setLoading(false)
                navigate(ROUTES.cardList)
                setNavigateToCardList(false)
            }
        }
    }, [cardListSuccess, cardListError])

    useEffect(() => {
        setDisplayQrCode(false)
        setQRCodeUrl('')
        setDisplayQrCode(false)
        setForColor('#000000')
        setBackColor('#ffffff')
        setQrLogoImage(null);
        setQrLogoImageName('');
        setQrLogoImagePreview(null);
        setQrStyle('squares')
        setQrLogoStyle('square')
        setIsDynamicQr(false)
        setDynamicQrData(null)
        setQrSize(256)
        setQrImgSize({ width: 35, height: 17.5 })
        setLabelSize(14)
        setCustomTextSize(28)
    }, [qrCodeInput])

    useEffect(() => {
        if (!isNull(selectedType)) {
            if (selectedType.type === 'LINK' || selectedType.type === 'ADDRESS' || selectedType.type === 'SOCIAL' || selectedType.type === 'APP STORE' || selectedType.type === 'TEXT') {
                setLinkText('')
            } else if (selectedType.type === 'BUSINESS CARD') {
                handleCreateCardClick()
                // setFirstName('')
                // setLastName('')
                // setPhoneNumber('')
            } else if (selectedType.type === 'ONLINE MENU') {
                handleOnlineMenuClick()
            } else if (selectedType.type === 'VISITOR MANAGEMENT') {
                handleVisitorMgmtClick()
            } else if (selectedType.type === 'QME') {
                handleQMeClick()
            } else if (selectedType.type === 'JUSTGOMO') {
                handleJustGomoClick()
            } else if (selectedType.type === 'CALL') {
                setPhoneNumber('')
            } else if (selectedType.type === 'WHATSAPP') {
                setPhoneNumber('')
                setText('')
            } else if (selectedType.type === 'EMAIL') {
                setToEmail('')
                setEmailSubject('')
                setEmailBody('')
            } else if (selectedType.type === 'SMS') {
                setPhoneNumber('')
                setText('')
            } else if (selectedType.type === 'WIFI') {
                setSsid('')
                setSecurity('')
                setPassword('')
            }
        }
    }, [selectedType])

    const vCardData = `BEGIN:VCARD
VERSION:3.0
N:${lastName};${firstName};;;
FN:${firstName} ${lastName}
TEL;TYPE=CELL:${phoneNumber}
END:VCARD`;

    const handleGenerateClick = () => {
        if (selectedType.type === 'LINK' && isEmpty(linkText)) {
            displayErrorToast('Link cannot be blank')
        } else if (selectedType.type === 'BUSINESS CARD' && (isEmpty(firstName) || isEmpty(lastName) || isEmpty(phoneNumber))) {
            displayErrorToast('One or more field is blank')
        } else if (selectedType.type === 'TEXT' && isEmpty(linkText)) {
            displayErrorToast('Text cannot be blank')
        } else if (selectedType.type === 'CALL' && isEmpty(phoneNumber)) {
            displayErrorToast('Phone number cannot be blank')
        } else if (selectedType.type === 'WHATSAPP' && isEmpty(phoneNumber)) {
            displayErrorToast('Whatsapp number cannot be blank')
        } else if (selectedType.type === 'EMAIL' && (isEmpty(toEmail) || isEmpty(emailBody) || isEmpty(emailSubject))) {
            displayErrorToast('One or more field is blank')
        } else if (selectedType.type === 'SMS' && (isEmpty(phoneNumber) || isEmpty(text))) {
            displayErrorToast('One or more field is blank')
        } else if (selectedType.type === 'WIFI' && (isEmpty(ssid) || isEmpty(password) || isEmpty(security))) {
            displayErrorToast('One or more field is blank')
        } else if (selectedType.type === 'ADDRESS' && isEmpty(linkText)) {
            displayErrorToast('Address link is empty')
        } else if (selectedType.type === 'SOCIAL' && isEmpty(linkText)) {
            displayErrorToast('Social link cannot be blank')
        } else if (selectedType.type === 'APP STORE' && isEmpty(linkText)) {
            displayErrorToast('App store link cannot be blank')
        } else {
            let text1 = getQRCodeTextValue()
            setQRCodeUrl(text1)
            setQRCodeStaticUrl(text1)
            setDisplayQrCode(true)
        }
    }

    const getQRCodeTextValue = () => {
        if (selectedType.type === 'LINK' || selectedType.type === 'ADDRESS' || selectedType.type === 'SOCIAL' || selectedType.type === 'APP STORE' || selectedType.type === 'TEXT') {
            return linkText
        } else if (selectedType.type === 'BUSINESS CARD') {
            // text1 = `data:text/vcard;charset=utf-8,${encodeURIComponent(vCardData)}`
            return vCardData.replace(/^(?=\n)$|^\s*|\s*$|\n\n+/gm, "")
        } else if (selectedType.type === 'CALL') {
            // text1 = `tel:${phoneNumber}`
            return `tel://${phoneNumber}`
        } else if (selectedType.type === 'WHATSAPP') {
            return `https://wa.me/${phoneNumber}?text=${encodeURIComponent(text)}`
        } else if (selectedType.type === 'EMAIL') {
            // text1 = `mailto:${toEmail}?subject=${encodeURIComponent(emailSubject)}&body=${encodeURIComponent(emailBody)}`;
            // text1 = `MATMSG:TO:${toEmail};SUB:${encodeURIComponent(emailSubject)};BODY:${encodeURIComponent(emailBody)}`;
            return `SMTP:${toEmail}:${encodeURIComponent(emailSubject)}:${encodeURIComponent(emailBody)}`;
        } else if (selectedType.type === 'SMS') {
            // text1 = `sms:${phoneNumber}?body=${encodeURIComponent(text)}`;
            return `smsto:${phoneNumber}:${encodeURIComponent(text)}`;
        } else if (selectedType.type === 'WIFI') {
            return `WIFI:S:${ssid};T:${security};P:${password};;`
        }
    }

    const handleQrCodeClick = () => {
        if (selectedType.type === 'CALL') {
            window.location.href = `tel:${phoneNumber}`
        }
    }

    const handleSaveChangesClick = () => {
        if (!isNull(user)) {
            if (isDynamicQr === true) {
                if (!isNull(dyanmicQrData)) {
                    processUpdateDynamicQrCode()
                } else {
                    processAddQRCodeRequest(true)
                }
            } else {
                handleSaveLaterClick()
            }
        } else {
            setNavigateFlow(1)
            setFlowType(1)
            setShowLoginDialog(true)
        }
    }

    const handleSaveLaterClick = () => {
        if (!isNull(user)) {
            if (isEmpty(qrCodeUrl)) {
                displayErrorToast('QR code text cannot be empty')
            } else {
                processAddQRCodeRequest(false)
            }
        } else {
            setNavigateFlow(1)
            setFlowType(2)
            setShowLoginDialog(true)
        }
    }

    const processAddQRCodeRequest = (dyanmicQr) => {
        const fd = new FormData()
        fd.append('userId', user.data.userDetails.userId)
        fd.append('qrCodeText', getQRCodeTextValue())
        fd.append('label', !isEmpty(customeMessage) ? customeMessage : '')
        fd.append('forColor', forColor)
        fd.append('backColor', backColor)
        fd.append('qrStyle', qrStyle)
        fd.append('qrLogoStyle', qrLogoStyle)
        fd.append('type', selectedType.name)
        fd.append('isDynamicQr', dyanmicQr)
        let content = {
            type: selectedType.type
        }

        if (selectedType.type === 'LINK' || selectedType.type === 'ADDRESS' || selectedType.type === 'SOCIAL' || selectedType.type === 'APP STORE' || selectedType.type === 'TEXT') {
            content.linkText = linkText
        } else if (selectedType.type === 'BUSINESS CARD') {
            content.firstName = firstName
            content.lastName = lastName
            content.phoneNumber = phoneNumber
        } else if (selectedType.type === 'CALL') {
            content.phoneNumber = phoneNumber
        } else if (selectedType.type === 'WHATSAPP' || selectedType.type === 'SMS') {
            content.phoneNumber = phoneNumber
            content.text = text
        } else if (selectedType.type === 'EMAIL') {
            content.toEmail = toEmail
            content.emailSubject = emailSubject
            content.emailBody = emailBody
        } else if (selectedType.type === 'WIFI') {
            content.ssid = ssid
            content.security = security
            content.password = password
        }

        fd.append('content', JSON.stringify(content))
        if (!isNull(qrLogoImage) && !isEmpty(qrLogoImageName)) {
            fd.append('qrLogo', qrLogoImage, qrLogoImageName)
        }

        setLoading(true)
        dispatch(addQrCodeRequest(fd))
    }

    const processUpdateDynamicQrCode = () => {
        const fd = new FormData()
        fd.append('qrCodeId', dyanmicQrData.qrCodeId)
        fd.append('qrCodeText', getQRCodeTextValue())
        fd.append('label', !isEmpty(customeMessage) ? customeMessage : '')
        fd.append('forColor', forColor)
        fd.append('backColor', backColor)
        fd.append('qrStyle', qrStyle)
        fd.append('qrLogoStyle', qrLogoStyle)
        fd.append('type', selectedType.name)
        fd.append('isDynamicQr', isDynamicQr)
        let content = {
            type: selectedType.type
        }

        if (selectedType.type === 'LINK' || selectedType.type === 'ADDRESS' || selectedType.type === 'SOCIAL' || selectedType.type === 'APP STORE' || selectedType.type === 'TEXT') {
            content.linkText = linkText
        } else if (selectedType.type === 'BUSINESS CARD') {
            content.firstName = firstName
            content.lastName = lastName
            content.phoneNumber = phoneNumber
        } else if (selectedType.type === 'CALL') {
            content.phoneNumber = phoneNumber
        } else if (selectedType.type === 'WHATSAPP' || selectedType.type === 'SMS') {
            content.phoneNumber = phoneNumber
            content.text = text
        } else if (selectedType.type === 'EMAIL') {
            content.toEmail = toEmail
            content.emailSubject = emailSubject
            content.emailBody = emailBody
        } else if (selectedType.type === 'WIFI') {
            content.ssid = ssid
            content.security = security
            content.password = password
        }
        if (!isNull(qrLogoImage) && !isEmpty(qrLogoImageName)) {
            fd.append('qrLogo', qrLogoImage, qrLogoImageName)
        }

        fd.append('content', JSON.stringify(content))

        setLoading(true)
        dispatch(updateQrCodeRequest(fd))
    }

    const downloadQR = () => {
        if (isEmpty(downloadType)) {
            displayErrorToast('Download type not selected')
        } else {
            const width = svgRef.current.offsetWidth;
            let rNumber = Math.floor(100 + Math.random() * 900)
            if (downloadType === 'png') {
                toPng(svgRef.current, { width })
                    .then(function (dataUrl) {
                        download(dataUrl, `qr-code${rNumber}.png`);
                    })
                    .catch(function (error) {
                        console.error("oops, something went wrong!", error);
                    });
            } else if (downloadType === 'jpeg') {
                toJpeg(svgRef.current, { width })
                    .then(function (dataUrl) {
                        download(dataUrl, `qr-code${rNumber}.jpg`);
                    })
                    .catch(function (error) {
                        console.error("oops, something went wrong!", error);
                    });
            } else if (downloadType === 'svg') {
                toSvg(svgRef.current, { width })
                    .then(function (dataUrl) {
                        download(dataUrl, `qr-code${rNumber}.svg`)
                    }).catch(function (error) {
                        console.error("oops, something went wrong!", error);
                    });
            }
        }

    }

    const processRegistration = () => {
        if (isEmpty(mobile)) {
            displayErrorToast('Mobile number cannot be blank')
        } else if (!isValidPhoneNumber(mobile)) {
            displayErrorToast('Invalid mobile number')
        } else {
            setShowLoginDialog(false)
            setLoading(true)
            const mobileWithoutPlusSymbol = mobile?.replace('+', "")
            const data = {
                mobile: parseInt(mobileWithoutPlusSymbol),
                productCode: APP_CONSTANTS.PRODUCT_NAME,
                userType: APP_CONSTANTS.USER_TYPE_CUSTOMER
            }
            dispatch(registerByMobileRequest(data))
        }
    }

    const handleViewQrCodeListClick = () => {
        navigate(ROUTES.qrCodeList)
    }

    const handleCardClick = e => {
        setSelectedType(e)
        setQrCodeInput('')
        setQRCodeUrl('')
        setDisplayQrCode(false)
        setForColor('#000000')
        setBackColor('#ffffff')
        setQrLogoImage(null);
        setQrLogoImageName('');
        setQrLogoImagePreview(null);
        setQrStyle('squares')
        setQrLogoStyle('square')
        setIsDynamicQr(false)
        setDynamicQrData(null)
    }

    const handleQrLogoImageChange = event => {
        if (!isEmpty(event.target.files)) {
            setQrLogoImage(event.target.files[0])
            setQrLogoImageName(event.target.files[0].name)
            setQrLogoImagePreview(URL.createObjectURL(event.target.files[0]))
        }
    }

    const renderQRCode = () => {
        if (qrLogoImagePreview) {
            return (
                <QRCode
                    value={qrCodeUrl}
                    size={qrSize < 38 ? 38 : qrSize}
                    fgColor={forColor}
                    bgColor={backColor}
                    renderAs='svg'
                    imageSettings={{
                        src: `${qrLogoImagePreview ? qrLogoImagePreview : ''}`,
                        x: undefined,
                        y: undefined,
                        height: qrImgSize.height,
                        width: qrImgSize.width
                    }}
                />
            )
        } else {
            return (<QRCode
                value={qrCodeUrl}
                size={qrSize < 38 ? 38 : qrSize}
                fgColor={forColor}
                bgColor={backColor}
                renderAs='svg'
            />)
        }
    }

    const getHeadingText = () => {
        if (selectedType.type === 'LINK') {
            return 'Enter Link to any Website URL'
        } else if (selectedType.type === 'BUSINESS CARD') {
            return 'Enter Card details'
        } else if (selectedType.type === 'TEXT') {
            return 'Enter Text to share'
        } else if (selectedType.type === 'EMAIL') {
            return 'Enter Email details'
        } else if (selectedType.type === 'WHATSAPP') {
            return 'Enter Whatsapp details'
        } else if (selectedType.type === 'SMS') {
            return 'Enter Sms details'
        } else if (selectedType.type === 'WIFI') {
            return 'Enter Wifi details'
        } else if (selectedType.type === 'ADDRESS') {
            return 'Enter Address link'
        } else if (selectedType.type === 'SOCIAL') {
            return 'Enter Social page/profile link'
        } else if (selectedType.type === 'APP STORE') {
            return 'Enter App Store link to download app from Google/iOS'
        } else if (selectedType.type === 'CALL') {
            return 'Enter Call details'
        }
    }

    const getSubText = () => {
        if (selectedType.type === 'LINK') {
            return 'Ex. Website, Url, Page, Link (Start with http://...)'
        } else if (selectedType.type === 'ADDRESS') {
            return 'Enter address link. Select TEXT if text address'
        } else if (selectedType.type === 'SOCIAL') {
            return 'Ex. https://www.facebook.com/username'
        } else if (selectedType.type === 'WHATSAPP') {
            return 'Phone number must have country code without +'
        } else if (selectedType.type === 'APP STORE') {
            return 'Start with https://...'
        } else {
            return ''
        }
    }

    const getDisplaySubText = () => {
        if (selectedType.type === 'LINK') {
            return true
        } else if (selectedType.type === 'ADDRESS') {
            return true
        } else if (selectedType.type === 'SOCIAL') {
            return true
        } else if (selectedType.type === 'WHATSAPP') {
            return true
        } else if (selectedType.type === 'APP STORE') {
            return true
        } else {
            return false
        }
    }

    useEffect(() => {
        if (!isDynamicQr)
            setDisplayQrCode(false)
    }, [linkText, text, phoneNumber, firstName, lastName, toEmail, emailSubject, emailBody, ssid, password, security])

    useEffect(() => {
        if (!isDynamicQr) {
            setDynamicQrData(null)
        }
    }, [isDynamicQr])

    useEffect(() => {
        if (qrSize < 38) {
            displayErrorToast('QR Code size cannot be less than 38 pixels')
        }
        let newQrSize = qrSize < 38 ? 38 : qrSize
        let percentChange = Math.round((newQrSize - 38) / 38)
        let newWidth = 5 + (5 * percentChange)
        let newHeight = 2.5 + (2.5 * percentChange)
        let newLabelSize = 4 + (4 * percentChange)
        let newCustomTextSize = 8 + (8 * percentChange)
        setQrImgSize({ width: newWidth, height: newHeight })
        setLabelSize(newLabelSize / 2)
        setCustomTextSize(newCustomTextSize / 2)
    }, [qrSize])

    const handleCreateCardClick = () => {
        if (!isNull(user)) {
            if (!isNull(cardListSuccess) && !isEmpty(cardListSuccess.data) && cardListSuccess.data.length < 2) {
                navigate(ROUTES.addCard)
            } else {
                navigate(ROUTES.cardList)
            }
        } else {
            setNavigateFlow(1)
            setFlowType(3)
            setShowLoginDialog(true)
        }
    }

    const handleOnlineMenuClick = () => {
        if (config.environment === APP_CONSTANTS.ENVIRONMENT.PROD) {
            window.open("https://www.gomobites.com", "_blank");
        } else {
            window.open("https://try.gomobites.com", "_blank");
        }
    }

    const handleVisitorMgmtClick = () => {
        if (config.environment === APP_CONSTANTS.ENVIRONMENT.PROD) {
            window.open("https://vms.clicktran.com", "_blank");
        } else {
            window.open("https://try-vms.clicktran.com", "_blank");
        }
        // navigate(ROUTES.visitorHome)
    }

    const handleQMeClick = () => {
        if (config.environment === APP_CONSTANTS.ENVIRONMENT.PROD) {
            window.open("https://qme.clicktran.com", "_blank");
        } else {
            window.open("https://try-qme.clicktran.com", "_blank");
        }
    }

    const handleJustGomoClick = () => {
        if (config.environment === APP_CONSTANTS.ENVIRONMENT.PROD) {
            window.open("https://justgomo.clicktran.com", "_blank");
        } else {
            window.open("https://try-justgomo.clicktran.com", "_blank");
        }
    }

    const fetchCardList = () => {
        setLoading(true)
        const data = {
            userId: user.data.userDetails.userId
        }
        dispatch(digitalCardListRequest(data))
    }

    const handleCardsClick = () => {
        navigate(ROUTES.homeCards)
    }

    const handleVisitorManagementClick = () => {
        navigate(ROUTES.visitorHome)
    }

    const handleFeedbackManagementClick = (e) => {
        if (config.environment === APP_CONSTANTS.ENVIRONMENT.PROD) {
            window.open('https://feedback.clicktran.com', '_blank')
        } else {
            window.open('https://try-feedback.clicktran.com', '_blank')
        }
    }

    const getContainerClassName = (e) => {
        if (e.isDifferentColor) {
            if (e.type === 'ONLINE MENU') {
                return 'cqc-item-container cqc-item-orange-container'
            } else if (e.type === 'BUSINESS CARD') {
                return 'cqc-item-container cqc-item-lilac-container'
            } else if (e.type === 'VISITOR MANAGEMENT') {
                return 'cqc-item-container cqc-item-visitor-container'
            } else if (e.type === 'QME') {
                return 'cqc-item-container cqc-item-qme-container'
            } else if (e.type === 'JUSTGOMO') {
                return 'cqc-item-container cqc-item-just-gomo-container'
            }
        } else {
            if (!isEmpty(selectedType) && selectedType.id === e.id) {
                return 'cqc-item-selected-container'
            } else {
                return 'cqc-item-container'
            }
        }
    }

    const getLabelClassName = (e) => {
        if (e.isDifferentColor) {
            if (e.type === 'ONLINE MENU') {
                return 'cqc-item-orange-label'
            } else if (e.type === 'BUSINESS CARD') {
                return 'cqc-item-lilac-label'
            } else if (e.type === 'VISITOR MANAGEMENT') {
                return 'cqc-item-visitor-label'
            } else if (e.type === 'QME') {
                return 'cqc-item-qme-label'
            } else if (e.type === 'JUSTGOMO') {
                return 'cqc-item-just-gomo-label'
            }
        } else {
            if (!isEmpty(selectedType) && selectedType.id === e.id) {
                return 'cqc-item-selected-label'
            } else {
                return 'cqc-item-label'
            }
        }
    }

    const handleForTeamsClick = () => {
        navigate(ROUTES.corporateLogin)
    }

    return (
        <>
            <CommonScreenContent>
                {isLogin === false && isNull(user) && <div className='hp-nav-header'>
                    <div className="hp-logo-container margin-left-10">
                        <img alt="" src={require('../../../assets/nLogo2.png')} className='img' />
                    </div>
                    <div className="nav-item-button-container">
                        <div className="nav-product-btn black-font" onClick={() => handleCardsClick()}>CARDS</div>
                        <div>
                            <div
                                ref={anchorRef}
                                id="composition-button"
                                aria-controls={open ? 'composition-menu' : undefined}
                                aria-expanded={open ? 'true' : undefined}
                                aria-haspopup="true"
                                onClick={handleToggle}
                                className="nav-product-btn black-font"
                            >
                                SOLUTIONS
                            </div>
                            <Popper
                                open={open}
                                anchorEl={anchorRef.current}
                                role={undefined}
                                placement="bottom-start"
                                transition
                                disablePortal
                            >
                                {({ TransitionProps, placement }) => (
                                    <Grow
                                        {...TransitionProps}
                                        style={{
                                            transformOrigin:
                                                placement === 'bottom-start' ? 'left top' : 'left bottom',
                                        }}
                                    >
                                        <Paper>
                                            <ClickAwayListener onClickAway={handleClose}>
                                                <MenuList
                                                    autoFocusItem={open}
                                                    id="composition-menu"
                                                    aria-labelledby="composition-button"
                                                    onKeyDown={handleListKeyDown}
                                                    className="nav-menu-list-container"
                                                >
                                                    <MenuItem onClick={handleVisitorManagementClick}>Visitor Management</MenuItem>
                                                    <MenuItem onClick={handleFeedbackManagementClick}>Feedback Management</MenuItem>
                                                </MenuList>
                                            </ClickAwayListener>
                                        </Paper>
                                    </Grow>
                                )}
                            </Popper>
                        </div>

                        {/* <div className="nav-product-btn black-font" onClick={() => handleVisitorManagementClick()}>VISITOR MANAGEMENT</div> */}


                        {/* <button type="button" className="btn hp-login-button ms-2" onClick={() => { handleLoginClick() }}>Login</button> */}
                    </div>
                </div>}
                {isLogin === false && isNull(user) && <h1 className='cqc-sub-title header-title'>Create Custom QR Code</h1>}
                {isLogin === false && isNull(user) && <div className="cqc-container1-text">
                    <h5 className="cqc-container1-heading-text">Discover the power of QR codes in modern marketing and communication. Our user-friendly platform enables individuals and businesses to effortlessly generate custom static or dynamic QR codes.</h5>
                    <h6 className="cqc-container1-heading-text">Simply select the options that meet your specific requirements, and effortlessly create your personalized QR code.</h6>
                </div>}
                {isLogin === true && !isNull(user) && <div className="mt-2 mb-1">
                    <CommonInputRow>
                        <ScreenLabel label='Generate QR Code' />
                        {!isNull(user) && <TopButton label='View Saved QR Codes' onClick={() => { handleViewQrCodeListClick() }} />}
                    </CommonInputRow>
                </div>}
                <CommonColorForm className='cqc-content-container-bg'>
                    {isLogin === true && !isNull(user) && <SubTextLabel label='Choose from below options to generate your QR Code' />}
                    <div className="cqc-icon-parent-container">
                        {data.map(e => (
                            <div className={`${getContainerClassName(e)}`}
                                onClick={() => handleCardClick(e)}>
                                <div className="cqc-icon-container">
                                    <img src={!isEmpty(selectedType) ? selectedType.id === e.id ? e.selImage : e.image : e.image} className='img' alt='icon' loading="lazy" />
                                </div>
                                <div className={`${getLabelClassName(e)}`}>{e.name}</div>
                            </div>))}
                    </div>
                    {selectedType && selectedType.type !== 'BUSINESS CARD' && selectedType.type !== 'ONLINE MENU' && selectedType.type !== 'VISITOR MANAGEMENT' && selectedType.type !== 'QME' && <div className="row mt-3">
                        <div className="col-sm-6">
                            {/* <SubTextLabel label={`Enter ${!isEmpty(selectedType) ? `${convertToPascal(selectedType.name)}` : ''} text to generate QR Code`} /> */}
                            <SubTextLabel label={getHeadingText()} />
                            {(selectedType.type === 'LINK' || selectedType.type === 'ADDRESS' || selectedType.type === 'SOCIAL' || selectedType.type === 'APP STORE' || selectedType.type === 'TEXT') &&
                                <CommonInputFloatFull
                                    onChange={t => setLinkText(t.target.value)}
                                    value={linkText}
                                    placeholder="Type Here"
                                    subText={getSubText()}
                                    isSubtext={getDisplaySubText()}
                                    type='text'
                                    isRequired={true}
                                />}
                            {/* {selectedType.type === 'BUSINESS CARD' && <div>
                                <CommonInputRow>
                                    <CommonInputFloatHalf
                                        onChange={t => setFirstName(t.target.value)}
                                        value={firstName}
                                        placeholder="First Name"
                                        isRequired={true}
                                        type='text'
                                    />
                                    <CommonInputFloatHalf
                                        onChange={t => setLastName(t.target.value)}
                                        value={lastName}
                                        placeholder="Last Name"
                                        isRequired={true}
                                        type='text'
                                    />
                                </CommonInputRow>
                                <CommonInputFloatFull
                                    onChange={t => setPhoneNumber(t.target.value)}
                                    value={phoneNumber}
                                    placeholder="Phone Number"
                                    subText={getSubText()}
                                    isSubtext={getDisplaySubText()}
                                    type='number'
                                    isRequired={true}
                                />
                            </div>} */}
                            {selectedType.type === 'CALL' && <CommonInputFloatFull
                                onChange={t => setPhoneNumber(t.target.value)}
                                value={phoneNumber}
                                placeholder="Phone Number"
                                subText={getSubText()}
                                isSubtext={getDisplaySubText()}
                                isRequired={true}
                                type='number'
                            />}
                            {selectedType.type === 'WHATSAPP' && <div>
                                <CommonInputFloatFull
                                    onChange={t => setPhoneNumber(t.target.value)}
                                    value={phoneNumber}
                                    placeholder="Phone Number"
                                    subText={getSubText()}
                                    isSubtext={getDisplaySubText()}
                                    isRequired={true}
                                    type='number'
                                />
                                <CommonInputFloatFull
                                    onChange={t => setText(t.target.value)}
                                    value={text}
                                    placeholder="Message"
                                    type='text'
                                />
                            </div>}
                            {selectedType.type === 'EMAIL' && <div>
                                <CommonInputFloatFull
                                    onChange={t => setToEmail(t.target.value)}
                                    value={toEmail}
                                    placeholder="To"
                                    type='text'
                                    isRequired={true}
                                />
                                <CommonInputFloatFull
                                    onChange={t => setEmailSubject(t.target.value)}
                                    value={emailSubject}
                                    placeholder="Subject"
                                    type='text'
                                    isRequired={true}
                                />
                                <CommonInputFloatFull
                                    onChange={t => setEmailBody(t.target.value)}
                                    value={emailBody}
                                    placeholder="Body"
                                    type='text'
                                    isRequired={true}
                                />
                            </div>}
                            {selectedType.type === 'SMS' && <div>
                                <CommonInputFloatFull
                                    onChange={t => setPhoneNumber(t.target.value)}
                                    value={phoneNumber}
                                    placeholder="Phone Number"
                                    type='number'
                                    isRequired={true}
                                />
                                <CommonInputFloatFull
                                    onChange={t => setText(t.target.value)}
                                    value={text}
                                    placeholder="Message"
                                    type='text'
                                    isRequired={true}
                                />
                            </div>}
                            {selectedType.type === 'WIFI' && <div>
                                <CommonInputFloatFull
                                    onChange={t => setSsid(t.target.value)}
                                    value={ssid}
                                    placeholder="SSID"
                                    type='text'
                                    isRequired={true}
                                />
                                <CommonInputFloatFull
                                    onChange={t => setPassword(t.target.value)}
                                    value={password}
                                    placeholder="Password"
                                    type='text'
                                    isRequired={true}
                                />
                                <CommonInputFloatFull
                                    onChange={t => setSecurity(t.target.value)}
                                    value={security}
                                    placeholder="Security (Ex. WPA/WEP/None)"
                                    type='text'
                                    isRequired={true}
                                />
                            </div>}
                            {/* <CommonInputFloatFull
                                onChange={t => setQrCodeInput(t.target.value)}
                                value={qrCodeInput}
                                placeholder="Type Here"
                                subText={getSubText()}
                                isSubtext={getDisplaySubText()}
                            /> */}
                            {/* <Input className="mt-2"
                                onChange={ }
                                value={qrCodeInput}
                                placeholder="Type Here"
                                inputProps={ariaLabel}
                                fullWidth /> */}
                            {displayQrCode && <FormFieldLabel className='up-bold-label mt-3' label='Personalizations' />}
                            {displayQrCode && <Input className="mt-3"
                                onChange={t => setCustomMessage(t.target.value)}
                                value={customeMessage}
                                placeholder="Custom Message"
                                inputProps={ariaLabel}
                                fullWidth />}
                            {displayQrCode && <div className="cqc-dynamic-row-container">
                                <FormFieldLabel label='Update to Dyanmic QR Code' />
                                <Switch {...label} checked={isDynamicQr} onChange={e => setIsDynamicQr(e.target.checked)} />
                            </div>}
                            {displayQrCode && <FormFieldLabel label='Select QR Code Color' />}
                            {displayQrCode && <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                defaultValue={forColor}
                            >
                                <FormControlLabel onChange={e => setForColor('#000000')} value="#000000" control={<Radio />} label="Black" />
                                <FormControlLabel onChange={e => setForColor('#ffffff')} value="#ffffff" control={<Radio />} label="White" />
                                <FormControlLabel onChange={e => setForColor('#9e9e9e')} value="#9e9e9e" control={<Radio />} label="Grey" />
                                <FormControlLabel onChange={e => setForColor('#2196f3')} value="#2196f3" control={<Radio />} label="Blue" />
                                <FormControlLabel onChange={e => setForColor('#ff9800')} value="#ff9800" control={<Radio />} label="Orange" />
                                <FormControlLabel onChange={e => setForColor('#f44336')} value="#f44336" control={<Radio />} label="Red" />
                                <FormControlLabel onChange={e => setForColor('#4caf50')} value="#4caf50" control={<Radio />} label="Green" />
                                <FormControlLabel onChange={e => setForColor('#ffeb3b')} value="#ffeb3b" control={<Radio />} label="Yellow" />

                            </RadioGroup>}
                            {displayQrCode && <FormFieldLabel className='mt-3' label='QR Code Background Color' />}
                            {displayQrCode && <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                defaultValue={backColor}
                            >
                                <FormControlLabel onChange={e => setBackColor('#000000')} value="#000000" control={<Radio />} label="Black" />
                                <FormControlLabel onChange={e => setBackColor('#ffffff')} value="#ffffff" control={<Radio />} label="White" />
                                <FormControlLabel onChange={e => setBackColor('#9e9e9e')} value="#9e9e9e" control={<Radio />} label="Grey" />
                                <FormControlLabel onChange={e => setBackColor('#2196f3')} value="#2196f3" control={<Radio />} label="Blue" />
                                <FormControlLabel onChange={e => setBackColor('#ff9800')} value="#ff9800" control={<Radio />} label="Orange" />
                                <FormControlLabel onChange={e => setBackColor('#f44336')} value="#f44336" control={<Radio />} label="Red" />
                                <FormControlLabel onChange={e => setBackColor('#4caf50')} value="#4caf50" control={<Radio />} label="Green" />
                                <FormControlLabel onChange={e => setBackColor('#ffeb3b')} value="#ffeb3b" control={<Radio />} label="Yellow" />

                            </RadioGroup>}
                            {/* {displayQrCode && <FormFieldLabel className='mt-3' label='QR Code Style' />} */}
                            {/* {displayQrCode && <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                defaultValue={qrStyle}
                            >
                                <FormControlLabel onChange={e => setQrStyle('squares')} value="squares" control={<Radio />} label="Square" />
                                <FormControlLabel onChange={e => setQrStyle('dots')} value="dots" control={<Radio />} label="Dots" />

                            </RadioGroup>} */}
                            {displayQrCode && <FormFieldLabel label='Logo' />}
                            {displayQrCode && <div className="av-icon-container">
                                <label htmlFor="productFileInput">
                                    <img src={require('../../../assets/camera_icon.png')} className="img" alt="camera" loading="lazy" />
                                </label>
                                <input
                                    style={{ display: "none" }}
                                    type="file"
                                    onChange={handleQrLogoImageChange}
                                    id='productFileInput'
                                />
                            </div>}
                            {/* {displayQrCode && qrLogoImagePreview && <FormFieldLabel className='mt-3' label='QR Code Logo Style' />}
                            {displayQrCode && qrLogoImagePreview && <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                defaultValue={qrLogoStyle}
                            >
                                <FormControlLabel onChange={e => setQrLogoStyle('square')} value="square" control={<Radio />} label="Square" />
                                <FormControlLabel onChange={e => setQrLogoStyle('circle')} value="circle" control={<Radio />} label="Circle" />

                            </RadioGroup>} */}

                            {/* <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={isSaveForLater}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                        color="success"
                                        size="medium"
                                        onChange={e => setIsSaveForLater(e.target.checked)}
                                    />}
                                label='Save for later'
                                className="mt-2"
                            /> */}
                            <div className="cqc-button-container mt-5">
                                {displayQrCode && isDynamicQr && <CommonButton isInverted label='Save Changes' onClick={() => { handleSaveChangesClick() }} />}
                                {displayQrCode && !isDynamicQr && <CommonButton isInverted label='Save for Later' onClick={() => { handleSaveLaterClick() }} />}
                                {!displayQrCode && <CommonButton label='Generate' onClick={() => { handleGenerateClick() }} />}
                            </div>
                        </div>
                        <div className="col-sm-6 text-center">
                            {/* <div className="qr-name-label">QR Code will display here</div> */}
                            <div className="cqc-qr-container-parent">
                                {displayQrCode && <div id="qrCode" className="cqc-qr-container" ref={svgRef}>
                                    <div className="qr-code">
                                        {renderQRCode()}
                                        {/* <img src={require('../../../assets/NewLogo.png')} alt="Logo" /> */}
                                    </div>
                                    <div className="cqc-powered-label" style={{ fontSize: labelSize }}>Powered By ClickTran.com</div>
                                    <div className="qr-code-footer">
                                        <div className="qr-footer-text" style={{ fontSize: customTextSize }}>{customeMessage}</div>
                                    </div>
                                </div>}
                            </div>
                            {displayQrCode && <FormFieldLabel className='mt-3' label='Select Download Format' />}
                            {displayQrCode && <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                defaultValue={downloadType}
                            >
                                <FormControlLabel onChange={e => setDownloadType('png')} value="png" control={<Radio />} label="PNG" />
                                <FormControlLabel onChange={e => setDownloadType('jpeg')} value="jpeg" control={<Radio />} label="JPEG" />
                                <FormControlLabel onChange={e => setDownloadType('svg')} value="svg" control={<Radio />} label="SVG" />
                            </RadioGroup>}
                            {displayQrCode && <CommonInputFloatFull
                                onChange={t => setQrSize(t.target.value)}
                                placeholder='Desired QR Code Size (In pixels)'
                                type='number'
                                value={qrSize}
                                isSubtext={true}
                                subText='Minimum size should be 38px (1cm)' />}
                            {displayQrCode && <ButtonRow>
                                {isDynamicQr && !isNull(dyanmicQrData) && <CommonButton isInverted label='Download' onClick={() => { downloadQR() }} />}
                                {!isDynamicQr && <CommonButton isInverted label='Download' onClick={() => { downloadQR() }} />}
                                {/* <CommonButton isInverted label='Download' onClick={() => { downloadQR() }} /> */}
                            </ButtonRow>}
                        </div>
                    </div>}
                </CommonColorForm>
                {isLogin === false && isNull(user) && <div className="cqc-container1">
                    <h4 className="cqc-container1-heading1">Static Vs Dynamic QR Code</h4>
                    <div className="row">
                        <div className="col-sm-2">
                            <div className="nhp-feature-icon-container1">
                                <img src={require('../../../assets/ic_qr_information.png')} className="img" alt="qr_information" loading="lazy" />
                            </div>
                        </div>
                        <div className="col-sm-10 cqc-sub-heading-label-container">
                            <span className="cqc-sub-heading-label">Information Persistence</span>
                            <div className="nhp-container1-sub-heading"><span className="cqc-container1-sub-heading-label">Static QR codes </span> retain the same information each time they are scanned, making them suitable for permanent or long-term use. Dynamic QR codes, on the other hand, allow for the updating of information, enabling real-time changes to the content they display.</div>
                            <div className="nhp-container1-sub-heading"><span className="cqc-container1-sub-heading-label">Dynamic QR codes </span> on the other hand, allow for the updating of information, enabling real-time changes to the content they display.</div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-2">
                            <div className="nhp-feature-icon-container1">
                                <img src={require('../../../assets/ic_qr_versatile.png')} className="img" alt="qr_versatile" loading="lazy" />
                            </div>
                        </div>
                        <div className="col-sm-10 cqc-sub-heading-label-container">
                            <span className="cqc-sub-heading-label">Versatility</span>
                            <div className="nhp-container1-sub-heading"><span className="cqc-container1-sub-heading-label">Static QR codes </span> are typically used for sharing fixed information like website URLs, contact details, or product descriptions that don't require frequent updates.</div>
                            <div className="nhp-container1-sub-heading"><span className="cqc-container1-sub-heading-label">Dynamic QR codes </span> offer greater versatility as they can be used for time-sensitive promotions, event registrations, inventory management, and other applications where information needs to be updated dynamically.</div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-2">
                            <div className="nhp-feature-icon-container1">
                                <img src={require('../../../assets/ic_qr_scalable.png')} className="img" alt="qr_scalable" loading="lazy" />
                            </div>
                        </div>
                        <div className="col-sm-10 cqc-sub-heading-label-container">
                            <span className="cqc-sub-heading-label">Scalability</span>
                            <div className="nhp-container1-sub-heading"><span className="cqc-container1-sub-heading-label">Static QR codes </span> are more straightforward to create and distribute since the content is fixed. They are suitable for small-scale marketing campaigns or personal use.</div>
                            <div className="nhp-container1-sub-heading"><span className="cqc-container1-sub-heading-label">Dynamic QR codes </span> require a platform or service to manage and update the information, which makes them more suitable for larger-scale marketing initiatives or scenarios where frequent changes are expected.</div>
                        </div>
                    </div>
                </div>}
            </CommonScreenContent >
            <Modal
                onHide={() => { setShowLoginDialog(false) }}
                show={showLoginDialog}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Login via Mobile Number
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="qr-container">
                        <div className="hp-login-image-container">
                            {/* <img src={require('../../../assets/GoMoBites.png')} className="img" /> */}
                            {/* <img src={require('../../assets/NewLogo2.png')} className="img" /> */}
                            <img alt="" src={require('../../../assets/nLogo2.png')} className="img" loading="lazy" />
                        </div>
                        <div className="mt-3 w-100">
                            <PhoneInput
                                defaultCountry="US"
                                placeholder="Mobile Number"
                                value={mobile}
                                onChange={setMobile}
                            />
                        </div>
                        <FormFieldLabel className='modal-btn-start' label="*No password required, only mobile number needed to login." />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="modal-btn-container">
                        <CommonButton isBig={true} onClick={() => processRegistration()}
                            label='Login' />
                        <div className="or-label-container"><span className="or-horizontal-line" /><span className="or-label">or</span><span className="or-horizontal-line" /></div>
                        <CommonButton isBig={true} isInverted={true} onClick={() => handleForTeamsClick()}
                            label='For Teams' />
                    </div>
                </Modal.Footer>
            </Modal>
            <ToastContainer />
            <Loader active={isLoading} text='Please wait...' />
        </>
    )
}

export default CreateCustomQrCode